<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site.bh-latitude-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site.bh-latitude-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Carte Latitude 2021/22</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">La carte Latitude est valable en tout temps durant la saison de ski 2021-2022, sauf du 27 décembre 2021 au 2 janvier 2022 inclusivement et les 8 samedis suivants : 15, 22 et 29 janvier 2022, 12 et 19 février 2022, 5, 19 et 26 mars 2022.</li>
          <li class="regular-text">La carte Latitude des Fêtes n’est pas de retour cette année. Si vous souhaitez venir durant les dates d’exclusion, veuillez consulter les billets multi-jours ou l’entrepôt du billet.</li>
          <li class="regular-text">Lorsque les journées initiales sont épuisées, obtenez 15 % de rabais sur vos visites subséquentes.</li>
          <li class="regular-text">Rabais applicables sur présentation de la carte Latitude, pour le détenteur seulement.</li>
        </ul>

        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">La carte Latitude doit être achetée en ligne ou par téléphone.</li>
          <li class="regular-text">Les journées additionnelles à 15 % de rabais doivent être achetées à la billetterie ou par téléphone.</li>
          <li class="regular-text">La carte Latitude est offerte aux résidents canadiens seulement. Preuve de résidence requise pour récupérer sa carte (carte d’identité délivrée par le gouvernement ou facture de services publics datée de moins de 6 mois).</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">La carte Latitude sera ajoutée sur la passe Tremblant que vous détenez déjà. Si vous achetez ce produit en ligne, vous devez ouvrir la session avec votre profil existant.</li>
          <li class="regular-text">Tout nouveau détenteur devra se présenter au Service à la clientèle ou au Centre Aventure du Sommet des Neiges pour récupérer sa passe Tremblant (pièce d’identité avec photo obligatoire).</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">La carte Latitude est remboursable jusqu’au 17 décembre 2021, si non utilisée. </li>
          <li class="regular-text">Journée expirée inutilisée ? La valeur payée sera applicable à l’achat d’un billet 1 jour à prix courant.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Limite d'un billet journalier escompté par détenteur, par jour.</li>
          <li class="regular-text">Les rabais ne s’appliquent pas aux billets de remontées ½ journée.</li>
          <li class="regular-text">Produit et rabais incessibles (ne peuvent être transférés d'une personne à une autre).</li>
          <li class="regular-text">La carte Latitude est offerte en exclusivité aux résidents canadiens. Si vous tentez d’acheter cette carte sans être résident canadien, votre carte ne sera pas activée.</li>
        </ul>

        <h2 class="small-title">Passeport vaccinal et preuve de vaccination</h2>
        <p class="regular-text">Pour les détenteurs de passes <a href="https://www.tremblant.ca/planifiez/billets-passes/passes-de-saison-de-ski/passes-ikon?sc_lang=fr" target="_blank">Ikon</a> et <a href="https://www.tremblant.ca/planifiez/billets-passes/passes-de-saison-de-ski/passe-tonik?sc_lang=fr" target="_blank">Tonik</a> ainsi que de <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski/carte-latitude?sc_lang=fr" target="_blank">cartes Latitude</a>, la vérification du passeport vaccinal ou de la preuve de vaccination combinée à une pièce d’identité se fera une seule fois, avant votre première visite, pour accéder aux remontées mécaniques cette saison. Deux options de validation seront disponibles dès le 6 novembre:</p>
        <ul>
          <li class="regular-text">en personne au Chalet des voyageurs</li>
          <li class="regular-text">par vidéoconférence en prenant rendez-vous sur notre plateforme virtuelle <a href="https://kiosk.ca1.qless.com/kiosk/app/home/19527" target="_blank">tremblant.ca/rdv</a></li>
        </ul>
        <p class="regular-text">Notez que pour assurer le respect des dernières annonces de la Santé publique du Québec, les passes des détenteurs de 13 ans et plus sont dorénavant désactivées, et ne seront réactivées que lorsque cette validation aura été effectuée.</p>
        <p class="regular-text">Pour les détenteurs de billets (SkiMax, multi-jours, entrepôt, groupes, autres), la vérification se fera en personne à l’un de nos comptoirs de billetteries. Pour les <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=fr" target="_blank">billets multi-jours</a>, une seule validation sera nécessaire pour l’ensemble des journées de validité de votre billet.</p>
        
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">2021/22 Latitude Card<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">The Latitude Card is valid at all times during the 2021-2022 ski season, except from December 27, 2021 to January 2, 2022 inclusive and the following 8 Saturdays: January 15, 22 and 29, February 12 and 19, March 5, 19 and 26, 2022</li>
          <li class="regular-text">The Holiday Season Latitude Card is not back this season. If you wish to come during the exclusion dates, please consult the multi-day ticket or ticket outlet. </li>
          <li class="regular-text">Once all initial days are used, your card entitles you to a 15% discount on additional visits.</li>
          <li class="regular-text">Discounts apply upon presentation of the Latitude Card.</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Latitude Card must be purchased online or by phone.</li>
          <li class="regular-text">Additional days at 15% off can be purchased at the ticket office or by phone.</li>
          <li class="regular-text">The Latitude Card is available to Canadian residents only. Proof of residency required to pick up your card (government-issued photo ID or utility bill dated less than 6 months old)</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">The Latitude Card will be added to your existing Tremblant Pass. If you purchase the product online, log in with your actual profile.</li>
          <li class="regular-text">New Passholders must pick up their Tremblant Pass at our Guest Services Desk or at the Centre Aventure of the Sommet des Neiges hotel. (photo ID required)</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">The Latitude Card can be refunded until December 17, 20121, if not used.</li>
          <li class="regular-text">Expired and unused days: The prepaid value will be applied to the purchase of a day ticket at the regular price</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Maximum of one regular discounted ticket per cardholder, per day.</li>
          <li class="regular-text">Rebates not applicable on half-day tickets.</li>
          <li class="regular-text">Non-transferable (product and discounts cannot be transferred from one person to the next).</li>
          <li class="regular-text">The Latitude Card is offered exclusively to Canadian residents. If you try to purchase this card and are not a Canadian resident, your pass will not be activated. </li>
        </ul>

        <h2 class="small-title">Vaccine passport and proof of vaccination</h2>
        <p class="regular-text">For <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-season-passes/ikon-passes" target="_blank">Ikon Pass</a>, <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-season-passes/tonik-pass" target="_blank">Tonik Pass</a> and <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets/latitude-card" target="_blank">Latitude Card</a> holders: Vaccine passport scanning or proof of vaccination verification and identification, will only be necessary one time. On November 6, Tremblant will begin offering two methods of this vaccination validation:</p>
        <ul>
          <li class="regular-text">In-person validation at the Chalet des Voyageurs</li>
          <li class="regular-text">Videoconference validation (for which you can book your appointment on our virtual platform <a href="https://kiosk.ca1.qless.com/kiosk/app/home/19527" target="_blank">tremblant.ca/rdv</a>)</li>
        </ul>
        <p class="regular-text">Please note: To ensure compliance with the latest announcements from Quebec Public Health authorities, Passes and Cards of holders aged 13 years and older have now been deactivated for use at Tremblant and will only be reactivated upon proper vaccination validation.</p>
        <p class="regular-text">Day ticket holders (Ski-max, multi-day, and others) will need to validate vaccination on-site. A single validation will be necessary for <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=en" target="_blank">multi-day tickets</a>.</p>

      </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
